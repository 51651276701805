div#clients {
  div.clients-content {
    div.container {
      background: $white;
      div.row.title {
        h2 {
          color: $text-black;
          margin-top: 30px;
          margin-bottom: 0;
        }
        h3 {
          color: $sb-aquamarine;
          font-weight: bold;
          margin-bottom: 30px;
        }
      }
      div.row.client-list {
        margin-top: 50px;
        div.grid {
          div.grid-item {
            margin-bottom: 30px;
            div.grid-item-content {
              p.client-name,p.client-company {
                font-size: 13px;
                line-height: 18px;
                margin-bottom: 3px;
              }
              p.client-name {
                font-weight: 400;
              }
              p.client-company {
                font-weight: 300;
              }
            }
            &:nth-child(1n+1) {
              div.grid-item-content {
                background-color: $sb-tan-50;
                h3, p {
                  color: $text-black;
                }
              }
            }
            &:nth-child(2n+1) {
              div.grid-item-content {
                background-color: $sb-prussian-blue-80;
                h3, p {
                  color: $white;
                }
              }
            }
            &:nth-child(3n+1) {
              div.grid-item-content {
                background-color: $jade-50;
                h3, p {
                  color: $text-black;
                }
              }
            }
            &:nth-child(4n+1) {
              div.grid-item-content {
                background-color: $sb-aquamarine;
                h3, p {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}
