// Essential Base Styles
@import "base/colours";
@import "base/variables";
@import "base/typography";
@import "base/mixins";

// Layout Styles
@import "layouts/navigation";
@import "layouts/homepage";
@import "layouts/who-we-are";
@import "layouts/richards-message";
@import "layouts/our-people";
@import "layouts/bio";
@import "layouts/clients";
@import "layouts/insights";
@import "layouts/insights-search";
@import "layouts/article";
@import "layouts/work-with-us";
@import "layouts/contact-us";
@import "layouts/downloads";
@import "layouts/quick-links";
@import "layouts/footer";
@import "layouts/services";
@import "layouts/flatpage";

// Component Styles
@import "components/accordian";
@import "components/hero-banner";
@import "components/large-banner-text";
@import "components/carousel";
@import "components/masonry";
@import "components/buttons";
@import "components/mailchimp";
@import "components/tables";

.top-buffer { margin-top: $large-margin;}

body {
  background-color: $pale_jade;
  padding-left: 60px !important;

  div.page {
    border-top: 10px solid $white;
    div.container {
      div.logo {
        margin-bottom: 50px;
        div {
          padding-right: 0;
          img {
            width: 75%;
            height: auto;
            display: block;
            margin-left: auto;
            background: #FFFFFF;
            padding: 15px 35px;
          }
        }
      }
    }
    @media screen and (max-width: $small-breakpoint) {
      border-top: none;
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    padding-left: 0 !important;
    padding-top: 40px !important;
  }
  div.cc-window {
    span.cc-message, div.cc-compliance {
      font-family: 'Montserrat', sans-serif;

    }
  }
  // Google Recaptcha Logo
  div.grecaptcha-badge {
    visibility: hidden;
  }
}
