div.carousel {
  ol.carousel-indicators {
    li {
      height: auto;
      background: $sb-aquamarine;
      color: $white;
      text-indent: 0;
      text-align: center;
      cursor: pointer;
      opacity: 0.5;
      margin: 0;
      &.active {
        opacity: 1;
      }
    }
  }
  a.carousel-control-prev, a.carousel-control-next {
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    // background: rgba(255,255,255,0.6);
  }
  a.carousel-control-next {
    background-image: url('../images/symbols/carousel/right-arrow.svg');
    span.carousel-control-next-icon {
      background: none;
    }
  }
  a.carousel-control-prev {
    background-image: url('../images/symbols/carousel/left-arrow.svg');
    span.carousel-control-prev-icon {
      background: none;
    }
  }
  div.carousel-inner {
    div.carousel-item {
      div.carousel-caption {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 5% 7%;
        h3 {
          font-size: 2.8rem;
          font-weight: 600;
          margin-bottom: 80px;
          line-height: 50px;
        }
        h5 {
          font-size: 1.4rem;
          font-weight: 500;
        }
        p {
          color: $white !important;
          font-size: 1.5rem;
          line-height: 2rem;
        }
      }
    }
  }
}
