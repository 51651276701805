body {
  &.fp-viewing-0 {
    div#fp-nav {
      visibility: hidden;
    }
  }
  &.fp-viewing-1 {
    div#fp-nav {
      ul {
        li {
          a {
            span {
              background: #FFFFFF;
            }
          }
        }
      }
    }
  }
  div#fp-nav {
    ul {
      li {
        a.active {
          span {
            background: $sb-aquamarine;
          }
        }
      }
    }
  }
  div#homepage {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    div.fullpage {
      div.section {
        height: 100vh;
        picture {
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
        div.container {
          @media screen and (max-width: $small-breakpoint) {
            padding-left: 45px;
            padding-right: 45px;
          }
        }
      }
      div#landing_section {
        div.fp-tableCell {
          vertical-align: top;
          padding-top: 100px;
          a.scrolling-arrow {
            display: inline-block;
            position: absolute;
            left: 50%;
            width: 24px;
            height: 24px;
            scroll-behavior: smooth;
            span {
              display: inline-block;
              width: 24px;
              height: 24px;
              border-left: 1px solid #FFFFFF;
              border-bottom: 1px solid #FFFFFF;
              transform: rotate(-45deg);
              animation: sdb04 2s infinite;
              box-sizing: border-box;
            }
            @keyframes sdb04 {
              0% {
                transform: rotate(-45deg) translate(0, 0);
              }
              20% {
                transform: rotate(-45deg) translate(-10px, 10px);
              }
              40% {
                transform: rotate(-45deg) translate(0, 0);
              }
            }
          }
          div.container {
            height: 90%;
            position: relative;
            div.row.align-items-start {
              img {
                display: block;
                width: 60%;
                height: auto;
                margin-left: auto;
                margin-right: auto;
                float: right;
              }
            }
            div.row.align-items-end {
              position: absolute;
              right: 15px;
              bottom: 0;
              width: 100%;
              h1 {
                text-transform: uppercase;
                font-weight: lighter;
                margin-bottom: 30px;
                position: relative;
                text-align: right;
                &:after {
                  content : "";
                  position: absolute;
                  right: 0;
                  bottom: -15px;
                  height: 1px;
                  width: 210px;  /* or 100px */
                  border-bottom: 1px solid $white;
                  @media screen and (max-width: $small-breakpoint) {
                    right: inherit;
                    left: 0;
                  }
                }
                @media screen and (max-width: $small-breakpoint) {
                  padding-right: 65px;
                  text-align: left;
                }
              }
            }
            h3 {
              color: $sb-aquamarine;
              font-weight: 400;
              text-align: right;
              @media screen and (max-width: $small-breakpoint) {
                padding-right: 65px;
                text-align: left;
              }
            }
              @media screen and (max-width: $small-breakpoint) {
                right: auto;
              }
            }
        }
        @media screen and (max-width: $large-breakpoint) {
          div.fp-tableCell {
            padding-top: 50px;
            div.col-lg-6 {
              img {
                float: left;
              }
            }
          }
        }
        @media screen and (max-width: $medium-breakpoint) {
          div.fp-tableCell {
            padding-top: 25px;
          }
        }
      }
      div#section_two {
        div.col-md-9 {
          padding: 30px;
        }
        h3 {
          font-size: 2rem;
          line-height: 2.7rem;
          text-align: center;
        }
        h1 {
          color: $white;
          font-size: 3.5rem;
          font-weight: bold;
          margin-top: 145px;
          text-align: center;
        }
        @media screen and (max-width: $small-breakpoint) {
          h1 {
            font-size: 38px;
            font-weight: 500;
            margin-bottom: 15px;
            margin-top: 100px;
          }
          h3 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
          }
          .container {
            padding-top: 30px;
            padding-bottom: 30px;
          }
        }
      }

      div#section_three {
        h1 {
          font-size: 5rem;
          line-height: 5rem;
          font-weight: 500;
          margin-bottom: 15px;
          text-align: left;
          color: #FFFFFF;
        }
        h3 {
          color: $white;
          text-align: left;
          font-size: 2.7rem;
          line-height: 3.1rem;
          span {
            color: $sb-aquamarine;
            font-weight: 400;
          }
        }
        @media screen and (max-width: $small-breakpoint) {
          h1 {
            font-size: 50px;
            line-height: 60px;
            text-align: center;
          }
          h3 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
            text-align: center;
          }
        }
      }
      div#section_four {
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        h1 {
          font-size: 6rem;
          font-weight: 500;
          margin-bottom: 15px;
          text-align: center;
        }
        h3 {
          color: $white;
          text-align: center;
          font-size: 24px;
          line-height: 30px;
          font-weight: 400;
          span {
            color: $sb-aquamarine;
            font-weight: 400;
          }
        }
        @media screen and (max-width: $small-breakpoint) {
          h1 {
            font-size: 55px;
          }
          h2 {
            font-size: 24px;
            line-height: 30px;
            font-weight: 400;
          }
        }
      }
      div#quote_section {
        background-color: $white;
        height: 400px !important;
        max-height: 400px !important;
        div.fp-tableCell {
          height: 400px !important;
          max-height: 400px !important;
          h1 {
            text-align: center;
            &:first-child {
              color: $prussian-blue;
              border-top: 1px solid $sb-aquamarine;
              padding-top: 30px;
            }
            &:nth-child(2) {
              color: $sb-aquamarine;
              border-bottom: 1px solid $sb-aquamarine;
              padding-bottom: 30px;
            }
          }
          p {
            color: $sb-aquamarine;
            text-align: right;
          }
        }
        @media screen and (max-width: $small-breakpoint) {
          h1 {
            font-size: 28px;
          }
        }
      }
      div#richard_message {
        div.container {
          position: relative;
          background: rgba(255, 255, 255, 0.8);
          padding-top: 40px;
          padding-bottom: 40px;
          div.image {
            padding-left: 40px;
            img {
              width: 100%;
              height: auto;
            }
          }
          div.content {
            padding-right: 40px;
            h2 {
              &:first-child {
                margin-bottom: 0;
              }
              strong {
                color: $sb-aquamarine;
                margin-bottom: 20px;
                font-weight: 300;
              }
            }
            h4 {
              margin-bottom: 20px;
            }
            p {
              margin-bottom: 10px;
              line-height: 24px;
              strong {
                font-weight: 400;
                color: $prussian-blue;
              }
            }
            a {
              display: block;
              color: $sb-aquamarine;
              &:after {
                content: url("../images/symbols/arrow.svg");
                display: inline-block;
                padding-left: 5px;
                width: 24px;
              }
              &:hover {
                text-decoration: none;
              }
              &:hover:after {
                transition: all 0.6s;
                transform: rotateX(1turn);
              }
            }
            h2, h4, p {
              color: $text-black;
            }
          }
        }
        @media screen and (max-width: $medium-breakpoint) {
          div.container {
            padding-top: 15px;
            padding-bottom: 15px;
            div.image {
              padding-left: 15px;
            }
            div.content {
              padding-right: 15px;
            }
          }
        }
        @media screen and (max-width: $small-breakpoint) {
          h2 {
            font-size: 7vw;
            display: inline;
          }
          p {
            margin-top: 10px;
            padding: 10px 0;
          }
          img {
            margin-bottom: 20px;
          }
        }
      }

      div#quick_links {
        background-color: #F4F4F5;
        div.quick-links {
          div.container {
            background: none;
            padding: 0;
            @media screen and (max-width: $small-breakpoint) {
              padding-left: 45px;
              padding-right: 45px;
              margin-bottom: 20px;
              h2 {
                padding-left: 0;
              }
            }
          }
        }
        @media screen and (max-width: $small-breakpoint) {
          padding-top: 30px;
        }
      }
      div#footer {
        background-color: $white;
        height: 400px !important;
        max-height: 400px !important;
        div.fp-tableCell {
          height: 400px !important;
          max-height: 400px !important;
        }
      }
    }
  }
}
