@import url('https://fonts.googleapis.com/css?family=Montserrat:200,200i,300,300i,400,400i,500,600,700,700i');

h1, h2, h3, h4, h5, h6, p, a, li, input, select, textarea, button, table, tr, td, th, label {
  font-family: 'Montserrat', sans-serif;
  color: $white;
  font-weight: 300;
}
h2 {
  font-size: 32px;
  line-height: 38.4px;
  font-weight: 300;
  span {
    font-weight: 400;
    color: $sb-aquamarine;
  }
  span.bold {font-weight: 500;color: inherit;}
}
h3 {
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 300;
}
h4 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 5px;
}
p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 20px;
}
#content {
	h1, h2, h3, h4, h5, h6, p, a, li {
		color: $text-black;
	}
  .highlight {color:$sb-aquamarine; font-weight:500;}
}
