div#our-people {
  h2 {
    color: $text-black;
  }
  p {
    color: $text-black;
  }
  div.row.search {
    margin: 15px 0;
    #search_input {
      width: 100%;
      border: 1px solid $text-black;
      border-radius: 0;
      text-align: left;
      color: $text-black;
      float: right;
      font-size: 14px;
      @media screen and (max-width: $medium-breakpoint) {
        float: none;
        margin-bottom: 20px;
      }
    }
    ul.department-list {
      padding-left: 0;
      float: right;
      margin-bottom: 0;
      li {
        display: inline-block;
        margin-bottom: 7px;
        a {
          font-size: 14px;
          padding: 3px 20px;
          color: $text-black;
          border: 1px solid $sb-aquamarine;
          transition: all 0.3s ease-in-out;
          &:hover {
            text-decoration: none;
            background-color: $sb-aquamarine;
            color: $white;
          }
          &.active {
            background: $sb-aquamarine;
            color: $white;
          }
        }
      }
    }
  }
  div.row.team-list {
    margin-bottom: 50px;
    #partner-text, #partner-text-deal-advisory {
      display: none;
    }
    .contact-card {
      display: block;
      margin-bottom: 30px;
      .card-wrapper {
        position: relative;
        padding: 0;
        overflow: hidden;
        a.mobile-link {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
        }
        .card-image{
          img {
            display: block;
            width: 100%;
            height: auto;
          }
          a {
            position: absolute;
            display: block;
            bottom: 20px;
            left: 20px;
            h3 {
              color: $sb-aquamarine;
            }
          }
        }
        .content-wrapper {
          position: absolute;
          top: 211px;
          left: 0;
          width: 80%;
          height: auto;
          min-height: 180px;
          background: rgba(0,0,0,0.5);
          padding: 15px;
          overflow: hidden;
          transition: all 0.5s ease-in-out;
          h3 {
            font-size: 18px;
            line-height: 24px;
          }
          a {
            &:hover {
              text-decoration: none;
              h3 {
                text-decoration: none;
                color: $sb-aquamarine;
                transition: all 0.2s;
              }
            }
          }
          div.card-content-wrapper {
            position: relative;
            display: block;
            min-height: 118px;
            height: auto;
            .card-content {
              position: absolute;
              bottom: 0;
              h3.name {
                font-size: 15px;
                line-height: 18px;
                text-transform: uppercase;
              }
              h4.specialties {
                font-size: 13px;
                line-height: 18px;
                white-space: normal;
                margin-bottom: 13px;
              }
              a {
                display: block;
                color: $white;
                font-size: 13px;
                line-height: 18px;
                white-space: normal;
                position: relative;
                padding-left: 20px;
                margin-bottom: 5px;
                transition: all 0.2s;
                &:hover {
                  text-decoration: none;
                  color: $sb-aquamarine;
                }
                &:first-child {
                  padding-left: 0;
                }
                &.phone {
                  &:before {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: -5px;
                    content: url('../images/symbols/ddi.svg');
                    width: 13px;
                    height: auto;
                  }
                }
                &.email {
                  &:before {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: url('../images/symbols/envelope.svg');
                    width: 15px;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
      &:hover {
        div.content-wrapper {
          top: 27%;
          bottom: 0 !important;
          a {
            h3 {
              text-decoration: none;
              color: $sb-aquamarine;
              transition: all 0.4s;
            }
          }
        }
      }
    }
  }
  div.quick-links {
    div.container {
      background: none;
    }
  }
  @media screen and (max-width: $medium-breakpoint) {
    div.row.logo {
      display: none;
    }
    div.row.search {
      ul.department-list {
        float: none;
      }
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    h2 {
      margin-top: 30px;
      font-size: 7vw;
      line-height: 8vw;
    }
    div.row.team-list {
      margin-bottom: 0;
      div.grid-item.contact-card {
        div.grid-item-content {
          div.content-wrapper {
            width: 100%;
            top: inherit;
            bottom: -125px;
          }
        }
      }
    }
  }
}
