div.grid {
  width: 100%;
  div.grid-item {
    div.grid-item-content {
      padding: 15px;
      p {
        color: $text-black;
      }
    }
  }
}
