div#richards_message {
  div.content {
    div.container {
      background-color: $white;
      padding-top: 40px;
      padding-bottom: 50px;
      div.row {
        div.bio {
          img {
            width: 100%;
            height: auto;
            margin-bottom: 20px;
          }
          h3,h4 {
            color: $prussian-blue;
            margin-bottom: 0;
          }
          a {
            color: $sb-aquamarine;
            display: block;
            margin-top: 10px;
            font-size: 24px;
            &:hover {
              text-decoration: none;
            }
            &:hover:after {
              transition: all 0.6s;
              transform: rotateX(1turn);
            }
          }
        }
        div.content {
          h2, h3, h4 {
            color: $text-black;
            strong {
              color: $sb-aquamarine;
              font-weight: 400;
            }
          }
          p {
            color: $text-black;
            span {
              color: $prussian-blue;
              font-weight: 500;
            }
          }
          h2, h3 {
            margin-bottom: 20px;
          }
          div#placeholder-arrow {
            width: 30px;
            height: 14px;
            display: block;
            position: relative;
            background: url('/static/images/symbols/arrow.svg');
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
