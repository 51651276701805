div#insights {
  div.container {
    &.content {
      padding-top: 30px;
      background: $white;
      padding-bottom: 50px;
    }
    div.logo {
      margin-bottom: 50px;
    }
    div.title {
      margin-bottom: 30px;
      h2 {
        color: $text-black;
        font-weight: 300;
        span {
          color: $sb-aquamarine;
          font-weight: 500;
        }
      }
    }
    div.filters {
      margin-bottom: 30px;
      form {
        display: inline-block;
        input#insights_search {
          cursor: pointer;
          padding: 3px 15px;
          background: url('../images/symbols/search.svg');
          margin-left: -5px;
          vertical-align: bottom;
          display: inline-block;
        }
        ul.errorlist {
          display: none;
          li {
            color: $text-black;
          }
        }
      }
      input {
        color: $text-black;
        border: 1px solid $text-black;
        padding: 3px 8px;
        font-size: 13px;
        height: 32px;
      }
      select {
        color: $text-black;
        border: 1px solid $text-black;
        border-radius: 0;
        height: 32px;
        float: right;
        margin-left: 5px;
      }
    }
    div.featured-articles {
      padding-left: 15px;
      padding-right: 15px;
      margin-bottom: 30px;
      overflow: hidden;
      transition: max-height 0.3s;
      max-height: 1000px;
      div.left {
        padding-left: 0;
        padding-right: 0;
        div.newsletter-sign-up {
          position: relative;
          width: 100%;
          height: auto;
          background: $sb-aquamarine;
          padding: 10px 20px;
          a {
            display: block;
            height: 100%;
            width: 100%;
            &:before {
              content: url('../images/symbols/insights/envelope.svg');
              display: inline-block;
              width: 35px;
              height: 35px;
              position: absolute;
              left: 30px;
              top: 11px;
            }
            &:hover {
              text-decoration: none;
            }
            p {
              width: 65%;
              font-size: 0.8rem;
              text-align: left;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 0;
              line-height: 18px;
            }
          }
        }
        div.article-wrapper {
          background: $sb-aquamarine;
          padding: 20px;
          a.article {
            display: block;
            position: relative;
            padding-bottom: 60px;
            &:nth-child(2) {
              margin-bottom: 100px;
            }
            &:after {
              content: url('../images/symbols/arrow-white-long.svg');
              display: inline-block;
              width: 60px;
              height: 20px;
              position: absolute;
              left: 0;
              bottom: 30px;
            }
            &:hover:after {
              transition: all 0.6s;
              transform: rotateX(1turn);
            }
            &:hover {
              text-decoration: none;
              h3 {
                text-decoration: none;
                color: $text-black;
                transition: all 0.3s;
              }
              p {
                text-decoration: none;
              }
            }
            h3 {

            }
            p {
              color: $sb-warm-grey;
              display: inline-block;
              font-size: 0.8rem;
              margin: 0;
              margin-left: 5px;
              span {
                color: $white;
              }
            }
          }
        }
      }
      div.right {
        padding-left: 0;
        padding-right: 0;
        div.article-wrapper {
          width: 100%;
          height: 100%;
          background-position: center center;
          background-size: cover;
          background-repeat: no-repeat;
          div.content-wrapper {
            display: block;
            position: absolute;
            right: 0;
            bottom: 30px;
            width: 85%;
            background: #262C31;
            a {
              position: relative;
              display: block;
              height: 100%;
              width: 100%;
              padding: 20px;
              &:hover {
                text-decoration: none;
                h3 {
                  text-decoration: none;
                  color: $sb-aquamarine;
                  transition: all 0.3s;
                }
                p {
                  text-decoration: none;
                }
              }
              p {
                color: #b8baba;
                display: inline-block;
                font-size: 13px;
                margin: 0;
                margin-bottom: 10px;
                margin-right: 5px;
                span {
                  color: $white;
                }
              }
            }

          }
        }
      }
    }
    div.blog-list {
      padding-left: 15px;
      padding-right: 15px;
      div.grid {
        div.grid-item {
          padding-left: 0;
          padding-right: 0;
          div.grid-item-content {
            padding: 0;
            a {
              display: block;
              position: relative;
              &:after {
                content: url('../images/symbols/arrow-blue-long.svg');
                position: absolute;
                bottom: 10px;
                left: 11px;
                width: 60px;
                height: 20px;
              }
              &:hover {
                text-decoration: none;
                div.content-wrapper {
                  h3.title {
                    text-decoration: none;
                    color: $prussian-blue;
                    transition: all 0.2s;
                  }
                  p {
                    text-decoration: none;
                  }
                }
              }
              &:hover:after {
                transition: all 0.6s;
                transform: rotateX(1turn);
              }
              img {
                display: block;
                width: 100%;
                height: 211px;
                object-fit: cover;
              }
              div.content-wrapper {
                width: 100%;
                height: 100%;
                min-height: 200px;
                background: #E2E5EC;
                padding: 10px;
                h3.title {
                  margin-top: 10px;
                  color: $text-black;
                }
                p {
                  display: inline-block;
                  margin: 0;
                  margin-right: 5px;
                  color: $text-black;
                  padding-bottom: 0;
                  font-size: 13px;
                  line-height: 17px;
                  color: #767b7f;
                  span {
                    color: $text-black;
                  }
                }
              }

            }
          }
          &:nth-child(2n+1) {
            div.grid-item-content {
              div.content-wrapper {
                background: $sb-tan-10;
              }
            }
          }
          &.press-releases {
            div.grid-item-content {
              a {
                &:after {
                  content: url('../images/symbols/arrow-white-long.svg');
                }
                &:hover {
                  div.content-wrapper {
                    h3.title {
                      color: $text-black;
                      transition: all 0.3s;
                    }
                  }
                }
                div.content-wrapper {
                  background: #32ACAA;
                  min-height: 411px;
                  p {
                    color: #D7EAEC;
                    span {
                      color: $white;
                    }
                  }
                  h3.title {
                    color: $white;
                  }
                }
              }
            }
          }
          &.reports {
            div.grid-item-content {
              a {
                &:after {
                  content: url('../images/symbols/arrow-white-long.svg');
                }
                &:hover {
                  div.content-wrapper {
                    h3.title {
                      color: $sb-prussian-blue-lightest;
                      transition: all 0.3s;
                    }
                  }
                }
                div.content-wrapper {
                  background:$sb-prussian-blue;
                  min-height: 411px;
                  p {
                    color: #D7EAEC;
                    span {
                      color: $white;
                    }
                  }
                  h3.title {
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    div.container {
      div.row.logo {
        display: none;
      }
      div.row {
        div.title {
          h2 {
            margin-top: 30px;
            font-size: 7vw;
            line-height: 8vw;
          }
        }
      }
      &.content {
        div.row.filters {
          div.col-12 {
            form, select {
              float: none;
              width: 100%;
              margin-left: 0;
              margin-bottom: 10px;
            }
          }
        }
        div.row.featured-articles {
          div.left {
            div.article-wrapper {
              a.article {
                &:nth-child(2) {
                  margin-bottom: 0;
                }
              }
            }
          }
          div.right {
            div.article-wrapper {
              padding-top: 50vw;
              div.content-wrapper {
                position: relative;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
