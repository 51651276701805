.modal-content, .form-control {border-radius:0px;}

#mailchimpModal, #newsletter-confirmation, #newsletter-email-already-subscribed, #newsletter-email-error{
  p, label, .modal-header h4{
    color: $text-black;
  }
  a {
  	color: $sb-aquamarine;
  }
  a.btn {
  	color: white;
  }
  input {
  	margin-bottom: $standard-margin;
  	color: $text-black;
  }
  .form-check {
    margin-bottom: $standard-margin;
  }
}
