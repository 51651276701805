div#downloads {
  div.title {
    h1 {
      color: $text-black;
    }
  }
  div.content {
    padding-top: 30px;
    padding-bottom: 30px;
    background: $white;
    p {
      color: $text-black;
      margin-bottom: 30px;
      span.highlight {
        color: $sb-aquamarine;
        font-weight: 400;
      }
    }
    table {
      width: 100%;
      margin-bottom: 30px;
      thead {
        tr {
          border: 1px solid $text-black;
          background: $white;
          th {
            padding: 10px;
            color: $text-black;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 2px solid $white;
          background: $sb-tan-50;
          border-left: 1px solid $sb-tan-50;
          td {
            color: $text-black;
            a {
              display: block;
              padding: 10px;
              background: $sb-tan-50;
              color: $text-black;
              transition: 0.3s all;
              &.active {
                background: $sb-tan;
              }
              &:hover {
                background: $sb-tan;
              }
            }
          }
        }
      }
    }
    div.related-links {
      padding-top: 87px;
      div.link {
        background: #d3d8e2;
        margin-bottom: 30px;
        &:after {
          display: block;
          position: relative;
          content: url('../images/symbols/arrow-blue-long.svg');
          width: 80px;
          height: 20px;
          bottom: 10px;
          left: 15px;
        }
        &:hover:after {
          transform: rotateX(1turn);
          transition: transform 0.6s;
        }
        a {
          display: block;
          &:hover {
            text-decoration: none;
          }
          img {
            width: 100%;
            height: auto;
          }
          h5 {
            margin: 20px 10px;
            color: $text-black;
            display: inline-block;
            span {
              color: $sb-aquamarine;
            }
          }
        }
      }
    }
  }
}
