div#who_we_are {
  div.content {
    div.container {
      background-color: $white;
      padding-top: 30px;
      div.row {
        h2 {
          color: $text-black;
          margin-bottom: 20px;
          strong {
            font-weight: 500;
            color: $sb-aquamarine;
          }
        }
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
          &.rocket-ship {
            width: 100px;
            height: 100px;
          }
        }
        p {
          color: $text-black;
          margin-bottom: 30px;
        }
        ul#who-we-are-tabs {
          li.nav-item {
            transition: 0.5s all;
            a {
              background-color: $sb-warm-grey;
              color: $white;
              border-radius: 0;
              border: none;
              padding: 8px 60px;
              transition: all 0.3s ease-in-out;
              margin-right: 2px;
              &.active {
                background-color: $sb-aquamarine;
              }
            }
          }
        }
        div#myTabContent {
          h2,h3,h4,h5 {
            color: $white;
          }
        }
        div.our-story {
          margin-top: 50px;
          h1 {
            color: $text-black;
            span {
              color: $sb-tan;
            }
          }
          div.wrapper {
            border: 1px solid $sb-tan;
            margin-top: 30px;
            margin-bottom: 50px;
            position: relative;
            &:before {
              content: url('../images/symbols/about-us-book.svg');
              width: 100px;
              height: auto;
              display: inline-block;
              position: absolute;
              left: -1px;
              top: -20px;
            }
            p {
              margin: 50px 50px 0 150px;
              display: inline-block;
            }
            img.our-story {
              width: 100%;
              height: auto;
              display: block;
              margin-top: 50px;
            }
            @media screen and (max-width: $small-breakpoint) {
              p {
                margin: 100px 10px 0 10px;
              }
            }
          }

        }

        &.mobile-content {
          div.col-12 {
            div.mobile-section {
              margin-bottom: 30px;
              div.mobile-header {
                display: block;
                position: relative;
                width: 100%;
                height: auto;
                h3.title {
                  display: block;
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 181px;
                  padding: 10px;
                  background-color: rgba(0, 0, 0, 0.7);
                  margin-bottom: 0;
                }
              }
              div.mobile-content {
                padding: 30px;
                &:nth-child(even) {
                  background-color: $jade;
                }
                &:nth-child(odd) {
                  background-color: $prussian-blue;
                }
                h4 {
                  margin-bottom: 25px;
                }
                p {
                  color: #FFFFFF;
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
