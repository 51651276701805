nav#navigation {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background: $white;
  z-index: 100;

  div#side-nav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    background-color: rgba(38,40,44,0.9);
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    margin-left: 60px;
    div.nav-wrapper {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      // min-width: 600px;
    }
    div.nav-items {
      div.row {
        ul {
          list-style-type: none;
          padding-left: 0;
          li {
            border-top: 2px solid $white;
            position: relative;
            &:last-child {
              border-bottom: 1px solid $white;
            }
            a {
              display: block;
              font-size: 35px;
              padding: 15px;
              transition: all 0.2s;
              &:hover {
                text-decoration: none;
                color: $sb-aquamarine;
              }
            }
            div.nav-expand {
              position: absolute;
              right: 10px;
              top: 0;
              transform: translateY(55%);
              padding: 0;
              border-radius: 50px;
              border: 2px solid $white;
              margin-right: 20px;
              cursor: pointer;
              width: 40px;
              height: 39px;
              &.open {
                div.bar1 {
                  -webkit-transform: rotateZ(1turn);
                  transform: rotateZ(1turn);
                }
                div.bar2 {
                  -webkit-transform: rotateZ(1.5turn);
                  transform: rotateZ(1.5turn);
                  width: 0;
                  height: 0;
                }
              }
              div.bar1 {
                position: relative;
                top: 16.5px;
                left: 8px;
                width: 20px;
                height: 2px;
                background: $white;
                transition: all 0.3s ease-in-out;
              }
              div.bar2 {
                position: relative;
                top: 5.5px;
                left: 17px;
                width: 2px;
                height: 20px;
                background: $white;
                transition: all 0.3s ease-in-out;
              }
            }
            ul.sub-nav {
              background: $sb-aquamarine;
              max-height: 0;
              transition: max-height 0.5s ease-out;
              overflow: hidden;
              width: 100%;
              &.open {
                max-height: 200px;
              }
              li {
                &:last-child {
                  border-bottom: none;
                }
                a {
                  background: $sb-aquamarine;
                  padding-left: 40px;
                  font-size: 20px;
                  &:hover {
                    color: $text-black;
                  }
                }
              }
            }
          }
        }
      }
    }
    div.nav-footer {
      div.left-links {
        ul {
          list-style-type: none;
          padding-left: 0;
          li {
            display: inline-block;
            padding-left: 15px;
            a {
              color: $white;
              transition: all 0.2s;
              text-transform: uppercase;
              font-size: 13px;
              &:hover {
                text-decoration: none;
                color: $sb-aquamarine;
              }
              &:after {
                content: url('../images/symbols/arrow.svg');
                display: inline-block;
                padding-left: 5px;
                width: 24px;
                padding-top: 2px;
              }
              &:hover:after {
                transition: all 0.6s;
                -webkit-transform: rotateX(1turn);
                transform: rotateX(1turn);
              }
              span {
                color: $sb-aquamarine;
              }
            }
          }
        }
      }
      div.right-links {
        ul {
          list-style-type: none;
          padding-left: 0;
          float: right;
          li {
            width: 120px;
            display: inline-block;
            padding-left: 15px;
            a {
              display: block;
              line-height: 19px;
              color: $sb-aquamarine;
              transition: all 0.2s;
              text-transform: uppercase;
              font-size: 13px;
              &:hover {
                color: $sb-aquamarine;
                text-decoration: none;
                span {
                  color: $sb-aquamarine;
                }
              }
              &:before {
                display: block;
                margin-right: 50px;
              }
              span {
                color: $white;
                transition: all 0.2s;
              }
            }
            &:nth-child(1) a:before {
              content: url('../images/symbols/client-portal.svg');
              width: 50px;
            }
            &:nth-child(2) a:before {
              content: url('../images/symbols/whats-new.svg');
              width: 50px;
            }
            &:nth-child(3) a:before {
              content: url('../images/symbols/newsletter.svg');
              width: 50px;
            }
            &:nth-child(4) a:before {
              content: url('../images/symbols/download.svg');
              width: 50px;
            }
          }
        }
      }
    }
  }

  div#nav-bar {
    z-index: 99;
    overflow: hidden;
    a {
      display: block;
      img#brand {
         width: 30px;
         height: auto;
         margin-left: 15px;
         margin-top: 20px;
      }
    }
    div#menu-hamburger {
      display: inline-block;
      position: absolute;
      width: 39px;
      height: 36px;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      padding: 5px 7px;
      border-radius: 50px;
      border: 2px solid $sb-aquamarine;

      .bar1, .bar2, .bar3 {
        width: 20px;
        height: 2px;
        background-color: $sb-aquamarine;
        margin: 4px 0;
        transition: 0.4s;
        border-radius: 50px;
      }
    }
  }
  &.open {
    div#side-nav {
      width: 90%;
    }
    div#nav-bar  {
      div#menu-hamburger {
        /* Rotate first bar */
        .bar1 {
          transform: rotate(-45deg) translate(-4px, 5px) ;
        }
        /* Fade out the second bar */
        .bar2 {
          opacity: 0;
        }
        /* Rotate last bar */
        .bar3 {
          transform: rotate(45deg) translate(-4px, -4px) ;
        }
      }
    }
  }
  @media screen and (max-width: $extra-large-breakpoint) {
    &.open {
      div#side-nav {
        width: 100%;
      }
    }
    div#side-nav {
      div.nav-items {
        div.row {
          ul {
            li {
              a {
                font-size: 30px;
              }
              div.nav-expand {
                transform: translateY(30%);
              }
            }
          }
        }
      }
      div.nav-footer {
        div.left-links {
          margin-top: 30px;
          ul {
            li {
              display: list-item;
              a {

              }
            }
          }
        }
        div.right-links {
          margin-top: 30px;
          ul {
            float: none;
            li {
              width: auto;
              a {
                &:before {
                  margin-right: 0;
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $large-breakpoint) {
    div#side-nav {
      padding-left: 0;
      div.nav-items {
        div.row {
          ul {
            li {
              a {
                font-size: 25px;
                padding: 12px;
              }
              div.nav-expand {
                transform: translateY(18%);
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $medium-breakpoint) {
    div#side-nav {
      div.nav-items {
        padding-left: 0;
        padding-right: 40px;
        div.row {
          ul {
            li {
              a {
                font-size: 4vw;
                padding: 10px;
              }
              div.nav-expand {
                width: 30px;
                height: 29px;
                div.bar1 {
                  top: 11.5px;
                  left: 6px;
                  width: 15px;
                }
                div.bar2 {
                  top: 3px;
                  left: 49%;
                  height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    width: 100%;
    height: 40px;
    div#nav-bar {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      a {
        img#brand {
          position: absolute;
          width: 20px;
          margin-top: 6px;
          right: 20px;
        }
      }
      div#menu-hamburger {
        width: 33px;
        left: 50%;
        transform: translateX(-50%);
        height: auto;
        top: 4px;
        div.bar1, div.bar2, div.bar3 {
          width: 15px;
          margin: 3px 0;
        }
      }
    }
    &.open {
      div#nav-bar {
        div#menu-hamburger {
          div.bar1 {
            transform: rotate(-45deg) translate(-3.5px, 4px);
          }
          div.bar3 {
            transform: rotate(45deg) translate(-3.5px, -3px);
          }
        }
      }
      div#side-nav {
        height: 100%;
      }
    }
    div#side-nav {
      margin-left: 0;
      margin-top: 40px;
      width: 100%;
      height: 0;
      div.nav-wrapper {
        top: 0;
        transform: translateY(0);
        overflow-y: auto;
        overflow-x: hidden;
        div.nav-items {
          width: 100%;
          padding-right: 0;
          margin-left: 0;
          ul {
            li {
              ul.sub-nav {
                li {
                  a {
                    font-size: 4vw;
                    padding-left: 20px;
                  }
                }
              }
            }
          }
        }
      }
      div.nav-footer {
        div.left-links {
          margin-top: 10px;
          ul {
            li {
              a {
                font-size: 3vw;
              }
            }
          }
        }
        div.right-links {
          margin-top: 0;
          ul {
            li {
              display: block;
              a {
                line-height: 24px;
                font-size: 3vw;
                &:before {
                  display: none;
                }
                &:after {
                  content: url('../images/symbols/arrow.svg');
                  display: inline-block;
                  padding-left: 5px;
                  width: 24px;
                  padding-top: 2px;
                }
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
