footer#footer {
  div.button-wrapper {
    background-color: $sb-aquamarine;
    div.container {
      @media screen and (max-width: $small-breakpoint) {
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
    ul {
      padding-left: 0;
      margin: 0;
      display: inline-block;
      li.button {
        display: inline-block;
        a {
          display: block;
          font-size: 12px;
          padding: 10px 15px;
          color: $white;
          text-transform: uppercase;
          transition: all 0.5s ease-in-out;
          &:hover {
            text-decoration: none;
          }
          &:before {
            display: block;
            float: left;
            width: 16px;
            height: auto;
            margin-right: 10px;
            transition: all 0.5s ease-in-out;
          }
        }
        &.client {
          background-color: #CDA787;
          a {
            // color: $text-black;
            &:before {
              content: url('../images/symbols/headshot.svg');
            }
          }
        }
        &.staff {
          background-color: $grey_blue;
          a {
            &:before {
              content: url('../images/symbols/headshot-hollow.svg');
            }
          }
        }
        &.download {
          background-color: #505154;
          a {
            &:before {
              content: url('../images/symbols/downloads.svg');
              margin-top: 1px;
            }
          }
        }
      }
      @media screen and (max-width: $small-breakpoint) {
        width: 100%;
        li.button {
          display: inline-block;
          width: 100%;
          a {
            white-space: nowrap;
            font-size: 3.5vw;
            text-align: center;
            font-weight: 400;
            &:before {
              width: 13px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    @media screen and (max-width: $small-breakpoint) {
      div.container {
        div.row {
          div.col-12 {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
  }
  div.content-wrapper {
    background-color: $sb-warm-grey;
    padding-top: 40px;
    div {
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
        li {
          font-size: 0.8rem;
          color: #e6e6e6;
          a {
            color: #e6e6e6;
            font-size: 0.8rem;
            font-weight: 200;
            display: block;
            margin-bottom: 3px;
            &:hover {
              color: $white;
              text-decoration: none;
              text-decoration-color: #e6e6e6;
            }
          }
        }
      }
      p {
        color: $white;
        font-size: 0.8rem;
        font-weight: 200;
        text-transform: uppercase;
        margin-bottom: 5px;
        a {
          color: $sb-aquamarine;
          font-size: 0.8rem;
          font-weight: 300;
          text-transform: lowercase;
          &:hover {
            text-decoration: none;
            text-decoration-color: $sb-aquamarine;
          }
        }
      }
      img {
        position: relative;
        width: 30%;
        height: auto;
        // float: right;
        @media screen and (max-width: $medium-breakpoint) {
          left: -11px;
        }
        @media screen and (max-width: $small-breakpoint) {
          margin-bottom: 10px;
        }
      }
    }
    div.row.social-links {
      border-top: 1px solid #706f6f;
      padding: 10px 0;
      margin-top: 40px;
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        float: right;
        clear: both;
        margin-right: 46px;
        li {
          display: inline-block;
          margin-left: 15px;
          a {
            display: block;
            height: 100%;
            img {
              width: 25px;
              height: 25px;
              margin-top: 0;
              left: 0;
            }
          }
        }
        @media screen and (max-width: $medium-breakpoint) {
          float: none;
          li {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
      p {
        text-transform: inherit;
        @media screen and (max-width: $small-breakpoint) {
          margin-bottom: 20px;
        }
        a {
          text-transform: inherit;
          color: #ccccce;
          font-weight: 400;
          &:hover {
            color: #FFFFFF;
            transition: color 0.3s;
          }
        }
      }
      @media screen and (max-width: $small-breakpoint) {
        margin-top: 10px;
      }
    }
  }
  div.extra-logos {
    background: $white;
    padding: 15px 0;
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        display: inline-block;
        padding: 0 10px;
        img {
          width: auto;
          height: 40px
        }
      }
    }
    p {
      font-size: 12px;
      line-height: 17px;
      color: $text-black;
      float: right;
      padding-top: 10px;
      margin: 0;
      &.copyright {
        margin-right: 45px;
      }
    }
    @media screen and (max-width: $small-breakpoint) {
      p {
        float: left;
        margin-right: 0;
        margin-top: 30px;
      }
    }
  }
}
