div.hero-banner {
  min-height: 600px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  div.container {
    display: block;
    position: relative;
    height: 100%;
    min-height: 600px;
    div.row.tag-line {
      div.col-md-5 {
        h2 {
          padding: 25px 0;
          border-top: 1px solid $white;
          border-bottom: 1px solid $white;
          font-size: 3rem;
          margin-top: 30px;
        }
      }
      @media screen and (max-width: $medium-breakpoint) {
        div.col-md-5 {
          h2 {
            margin-top: 100px;
            margin-bottom: 100px;
          }
        }
      }
    }
    div.row.page-name {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(0,0,0,0.7);
      padding: 20px 15px;
      h1 {
        margin-bottom: 0;
      }
    }
  }
  &#who_we_are_banner {
    background-image: url('../images/hero/about-us.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/about-us.jpg');
    }
  }
  &#wendys_message_banner {
    background-image: url('../images/hero/wendy-message.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/wendy-message.jpg');
    }
  }
  &#compliance_banner {
    background-image: url('../images/hero/compliance.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/compliance.jpg');
    }
  }
  &#beyond_compliance_banner {
    background-image: url('../images/hero/beyond-compliance.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/beyond-compliance.jpg');
    }
  }
  &#clients_banner {
    background-image: url('../images/hero/our-clients.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/our-clients.jpg');
    }
  }
  &#work_with_us_banner {
    background-image: url('../images/hero/work-with-us.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/work-with-us.jpg');
    }
  }
  &#contact_us_banner {
    background-image: url('../images/hero/contact-us.jpg');
    @media screen and (max-width: $small-breakpoint) {
      background-image: url('../images/hero/contact-us.jpg');
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    min-height: 300px;
    height: auto;
    div.container {
      min-height: 300px;
      div.row.logo {
        display: none;
      }
      div.row.tag-line {
        div.col-md-5 {
          h2 {
            font-size: 2rem;
          }
        }
      }
      div.row.page-name {
        h1 {
          font-size: 1.5rem;
        }
      }
    }
  }
}
