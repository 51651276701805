body #content .other-services-list {
  @media screen and (max-width: $medium-breakpoint) {
    margin-top: 50px;
  }
  .inner {
    background-color: $sb-aquamarine;

    padding: $standard-margin;
    ul {
      margin:0;
      padding:0;

      &.section {
        margin-top: $standard-margin/2;
        &:before {
          border-top: 1px solid white;
          content: " ";
          display:inline-block;
          width:70%;
          margin-left: 15%;
          margin-bottom:0.5rem;
        }
      }
      &.section:first-of-type {
        margin-top: $standard-margin/1.5;
        &:before {
          content: none;
          display:inline-block;
        }
      }

    }
    li {
      list-style:none;
      font-size: 14px;
      margin-bottom: 8px;
      &:last-of-type {
        margin-bottom:0rem;
      }
    }
    h1, h2, h3, h4, h5, h6, p, a, li {
      color: white;
    }
    h3 {
      margin-bottom: 15px;
    }
    .highlight {color:$text-black; }
  }
}

#content .key-service {
  margin-left: 0;
  margin-right: 0;
  &:first-child {
    margin-top: 0;
  }
  border: 1px solid $sb-tan;
  h3 {
    font-weight:400;
    line-height: 26px;
    margin-bottom: 13px;
  }
  .icon {
    // background-color: $jade;
    position:relative;
    top: - $standard-margin;
    left: -16px;
    width: auto;
    img {
      height: 100px;
      width: 100px;
    }
  }
  .text-wrapper{
    padding: $standard-margin;
  }
  a.link{
    display:block;
    color: $sb-aquamarine;
    @include linkarrow;
  }
}
div#content {
  &.compliance-content {
    div.container {
      background: $white;
      div.row.services {
        padding-top: 50px;
      }
    }
  }
}
