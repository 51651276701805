// GE Colours
$text-black: #10151D;
$white: #FFFFFF;
$jade: #81B4BF;
$jade-50: #A7CBD2;
$jade-70: #C1DBDF;
$pale_jade: #F3F8F9;
$red: #C51A1B;
$aquamarine-90: #59B0B2;
$aquamarine-70: #88C2C3;
$aquamarine-55: #A5CFD0;
$prussian-blue: #2D5574;
$grey_blue: #2C5574;

// SB&P Colours
// Primary Colours
$sb-warm-grey: #303B42;
$sb-warm-grey-lighter: #989ea3;
$sb-warm-grey-lightest: #c5cace;
$sb-aquamarine: #32ACAA;

// Secondary Colours
$sb-tan: #CDA787;
$sb-tan-50: #E0CBB9;
$sb-tan-10: #EFE5DD;
$sb-jade: #81B4BF;
$sb-prussian-blue: #2D5574;
$sb-prussian-blue-80: #54778D;
$sb-prussian-blue-lightest: #a3b3c8;
$sb-orange: #DB7737;
$sb-orange-90: #F36D32;
$sb-orange-lightest: #fee2ca;
