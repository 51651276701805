div#contact_us {
  div.content {
    div.container {
      padding-top: 40px;
      padding-bottom: 40px;
      background: $white;
      div.row.title {
        div {
          div.wrapper {
            border-bottom: 1px solid #a5cfd0;
            margin-bottom: 40px;
            h1, h2, h3 {
              color: $text-black;
              margin-bottom: 30px;
              span {
                color: $sb-aquamarine;
              }
            }
          }
        }
      }
      div.row.addresses {
        div.map {
          #map_wrapper {
            height: 500px;
          }
          #map_canvas {
            width: 100%;
            height: 100%;
            min-height: 500px;
          }
        }
        div.accordian {
          margin: 0;
          div.section {
            background-color: $sb-orange;
            div.title {
              div.accordian-expand {
                right: 10px;
                top: 10px;
                padding: 3px 8px;
                height: 38px;
                .bar1 {
                  width: 19px;
                }
                .bar2 {
                  top: 2px;
                  left: 9px;
                  height: 19px;
                }
              }
            }
            div.content {
              background: $white;
              h6 {
                color: $prussian-blue;
                font-weight: 400;
                margin: 15px 0;
              }
              div.address {
                padding: 15px;
                p {
                  color: $text-black;
                  margin-bottom: 0;
                }
              }
              div.contact {
                padding: 15px;
                margin-bottom: 20px;
                a {
                  color: $text-black;
                  display: block;
                  &:before {
                    display: inline-block;
                    width: 19px;
                    padding-right: 5px;
                    vertical-align: middle;
                    margin-right: 5px;
                  }
                  &.phone {
                    &:before {
                      content: url('../images/symbols/ddi.svg');
                    }
                  }
                  &.fax {
                    &:before {
                      content: url('../images/symbols/fax.svg');
                    }
                  }
                  &.email {
                    &:before {
                      content: url('../images/symbols/envelope.svg');
                    }
                  }
                }
              }
            }
          }
        }
      }
      div.row.get-in-touch {
        padding-top: 40px;
        h2 {
          color: $sb-aquamarine;
        }
        div.form {
          input, textarea {
            display: block;
            width: 100%;
            margin: 10px 0;
            border: 1px solid $text-black;
            padding: 10px;
            color: $text-black;
          }
          label {
            color: $text-black;
            display: inline-block;
            float: left;
            clear: both;
            text-align: left;
            width: 70%;
            padding-top: 5px;
            font-size: 13px;
            padding-left: 25px;
            @media screen and (max-width: $small-breakpoint) {
              padding-left: 40px;
              width: 100%;
            }
          }
          input[type=submit] {
            width: auto;
            background: $prussian-blue;
            border: 1px solid $prussian-blue;
            color: $white;
            padding: 2px 30px;
            transition: 0.5s all;
            float: right;
            margin-top: 0;
            &:hover {
              cursor: pointer;
              background-color: $white;
              color: $prussian-blue;
            }
          }
          input[type=checkbox] {
            display: block;
            width: auto;
            margin-left: 6px;
            float: left;
            position: absolute;
          }
          ul.messages {
            padding: 0 0 0 5px;
            margin: 55px 0 0 0;
            list-style-type: none;
            li {
              font-size: 1.2rem;
              color: $text-black;
            }
          }
        }
        div.tag-line {
          padding-left: 0;
          padding-top: 10px;
          div.wrapper {
            background: $sb-aquamarine;
            padding: 20px;
            h2 {
              color: $white;
              font-size: 26px;
              strong {
                font-weight: 400;
              }
              &:first-child {
                border-top: 1px solid $white;
                padding-top: 15px;
              }
              &:last-child {
                border-bottom: 1px solid $white;
                padding-bottom: 15px;
                margin-bottom: 5px;
              }
              @media screen and (max-width: $small-breakpoint) {
                font-size: 7vw;
              }
            }
          }
        }
      }
    }
  }
}
