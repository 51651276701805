div#insights-search {
  div.title {
    h1, h3 {
      color: $text-black;
      span {
        color: $sb-aquamarine;
      }
    }
  }
  div.row.button-list {
    background-color: #FFFFFF;
    div.col-12 {
      ul {
        li {
          margin-bottom: 0;
        }
      }
    }
  }
  div.content {
    div {
      background: $white;
      padding: 30px 0;
      ul.search-results {
        padding-right: 40px;
        li {
          color: $text-black;
          margin-bottom: 10px;
          list-style: none;
          // border-bottom: 1px solid $sb-aquamarine;
          margin-bottom: 40px;
          a {
            &:hover {
              text-decoration: none;
              cursor: pointer;
              h3:after {
                transform: rotateX(1turn);
                transition: transform 0.6s;
              }
            }
            h3 {
              color: $prussian-blue;
              &:after {
                content: url('../images/symbols/arrow-blue-long.svg');
                display: inline-block;
                width: 60px;
                height: auto;
                margin-left: 10px;
              }
            }
            p {
              color: $text-black;
            }
            p.author, p.date {
              display: inline-block;
              color: #767b7f;
              span {
                color: $text-black;
              }
            }
          }
        }
      }
    }
  }
}
