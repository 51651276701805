div.large-text-banner {
  min-height: 600px;
  div.container {
    display: block;
    position: relative;
    height: 100%;
    min-height: 600px;
    div.row.logo {
      margin-bottom: 50px;
      div.col-md-2 {
        background-color: $white;
        padding-top: 30px;
      }
    }
    div.row.content {
      position: absolute;
      bottom: 0;
      width: 100%;
      div.col-12 {
        padding: 50px 30px 30px 30px;
        background: rgba(255, 255, 255, 0.8);
        h2 {
          color: $text-black;
          span {
            color: $sb-aquamarine;
          }
        }
        p {
          color: $text-black;
        }
      }
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    min-height: 300px;
    height: auto;
    div.container {
      min-height: 300px;
      height: auto;
      div.row.logo {
        display: none;
      }
      div.row.content {
        position: relative;
      }
    }
  }
}
