div#bio-page {
  div.banner {
    div.container {
      position: relative;
      padding: 0;
      div.image-wrapper {
        position: relative;
        display: block;
        width: 104%;
        left: -2%;
        img#banner_photo {
          width: 100%;
          height: auto;
        }
      }
      div.content-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        div.quote-wrapper {
          position: absolute;
          bottom: 0;
          left: 50px;
          margin-bottom: 70px;
          width: 45%;
          max-width: 45%;
          div.quote {
            padding: 25px 0;
            border-top: 1px solid $white;
            border-bottom: 1px solid $white;
            h2 {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  div.bio-content {
    div.container {
      background-color: $white;
      color: $text-black;
      div.title {
        margin-bottom: 30px;
        h1.name, h4.qualifications {
          display: inline-block;
          color: $sb-aquamarine;
          margin: 10px;
        }
        h4.job-title {
          display: block;
          color: $prussian-blue;
          margin: 0 10px;
        }
        h1 {
          margin: 30px 10px 30px 0;
        }
      }
      div.bio {
        div.testimonial-wrapper {
          p {
            color: $text-black;
            margin-bottom: 0;
            text-align: right;
            font-size: 13px;
            line-height: 18px;
          }
          div.testimonial {
            background-color: $sb-aquamarine;
            padding: 20px;
            margin-bottom: 5px;
            h5 {
              color: $white;
              text-align: left;
              margin-bottom: 0;
              line-height: 30px;
            }
          }
        }
        div.bio-text {
          height: 100%;
          position: relative;
          border-top: 1px solid $sb-aquamarine;
          border-bottom: 1px solid $sb-aquamarine;
          color: $text-black;
          padding: 10px 0;
          p, li, strong, a {
            color: $text-black;
          }
          a {
            overflow-wrap: break-word;
          }
          strong {
            font-weight: 600;
          }
          p:last-child {
            margin-bottom: 0;
          }
          div.contact-info-wrapper {
            position: relative;
            width: 50%;
            float: right;
            background: #f2f2f3;
            margin-bottom: 1px;
            margin-top: 30px;
            transition: all 0.5s ease-in-out;
            div.title {
              background: $prussian-blue;
              padding: 7px;
              a {
                color: $white;
                text-transform: capitalize;
                &:hover {
                  text-decoration: none;
                }
              }
            }
            div.contact-info {
              padding: 12px;
              a {
                display: block;
                &:before {
                  display: inline-block;
                  width: 13px;
                  height: auto;
                  vertical-align: middle;
                  margin-right: 10px;
                }
                &:hover {
                  text-decoration: none;
                }
                &.phone {
                  &:before {
                    content: url('../images/symbols/ddi.svg');
                  }
                }
                &.mobile {
                  &:before {
                    content: url('../images/symbols/mobile.svg');
                  }
                }
                &.email {
                  &:before {
                    content: url('../images/symbols/envelope.svg');
                    width: 19px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  div.team-list {
    div.container {
      background: $white;
      div.accordian {
        div.title {
          cursor: pointer;
          div.accordian-expand {
            right: 30px;
            top: 10px;
            padding: 3px 8px;
            height: 38px;
            .bar1 {
              width: 19px;
            }
            .bar2 {
              top: 2px;
              left: 9px;
              height: 19px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $large-breakpoint) {
    div.banner {
      div.container {
        width: 100%;
        max-width: 100%;
        padding: 0;
        div.content-wrapper {
          div.quote-wrapper {
            bottom: -100px;
            margin-bottom: 0;
            padding: 30px;
            background-color: rgba(0,0,0,0.8);
            width: 80%;
            max-width: 80%;
            left: 10%;
            div.quote {
              h2 {
                font-size: 24px;
              }
            }
          }
        }
      }
    }
    div.bio-content {
      div.container {
        div.bio {
          div.bio-text {
            div.contact-info-wrapper {
              width: 75%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $medium-breakpoint) {
    div.bio-content {
      div.container {
        div.bio {
          div.testimonial-wrapper {
            div.testimonial {
              margin-bottom: 10px;
            }
            p {
              &:last-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $small-breakpoint) {
    div.banner {
      div.container {
        div.image-wrapper {
          width: 100%;
          left: 0;
        }
        div.row.logo {
          display: none;
        }
      }
    }
    div.bio-content {
      div.container {
        div.row.title {
          h1 {
            margin-bottom: 10px;
          }
          h4 {
            margin-bottom: 30px;
          }
        }
        div.row.bio {
          div.testimonial-wrapper {
            div.testimonial {
              margin-bottom: 10px;
              h5 {
                font-size: 4vw;
                line-height: 6vw;
              }
            }
          }
          div.bio-text {
            div.contact-info-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
