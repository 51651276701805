div#flatpage {
  div.container#content {
    margin-bottom: 50px;
    div.row.title {
      background: #FFF;
      padding: 30px;
      // margin-bottom: 30px;
      h2 {
        color: $text-black;
        span {
          color: $sb-aquamarine;
          font-weight: 400;
        }
      }
    }
    div.row.content {
      background: #FFF;
      padding: 30px;
      p,li,a,u {
        color: $text-black;
        text-decoration: none;
      }
      a {
        font-weight: 400;
        color: $sb-aquamarine;
      }
      table {
        td {
          span {
            color: $text-black;
          }
        }
        a {
          color: inherit;
        }
      }
    }
  }
}
