table.ge-table {
  width: 100%;
  margin-bottom: 30px;
  thead {
    tr {
      border: 1px solid $text-black;
      background: $white;
      th {
        padding: 10px;
        color: $text-black;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 2px solid $white;
      background: $jade-70;
      td {
        color: $text-black;
        a {
          display: block;
          padding: 10px;
          background: $jade-70;
          color: $text-black;
          transition: 0.3s all;
          &.active {
            background: $jade;
          }
          &:hover {
            background: $jade;
          }
        }
      }
    }
  }
}
table.stats {
  width: 65%;
  margin: 75px auto;
  thead {
    border-bottom: 2px solid $jade;
    tr {
      th {
        p {
          margin-bottom: 3px;
        }
        text-align: center;
        span {
          font-weight: 400;
        }
      }
    }
  }
  tbody {
    border-bottom: 2px solid $jade;
    tr {
      border-bottom: 1px solid $jade-50;
      td {
        &:first-child {
          p {
            text-align: left;
            margin-left: 7px;
          }
        }
        p {
          text-align: center;
          font-family: 'Montserrat', sans-serif;
          font-weight: 300;
          margin: 5px 0;
        }
      }
    }
  }
}
