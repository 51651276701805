div#work-with-us {
  div.content {
    div.container {
      background: $white;
      padding-top: 30px;
      h1 {
        color: $text-black;
        strong {
          color: $sb-aquamarine;
          font-weight: 400;
        }
      }
      h2 {
        color: $text-black;
        strong {
          color: $sb-aquamarine;
          font-weight: 400;
        }
      }
      p {
        color: $text-black;
        strong {
          color: $sb-aquamarine;
          font-weight: 400;
        }
      }
      div.row.quote {
        margin-top: 30px;
        div {
          padding: 40px;
          text-align: center;
          h1 {
            padding: 0 100px;
            &:first-child {
              border-top: 1px solid $sb-aquamarine;
              padding-top: 30px;
              margin-bottom: 10px;
              color: $sb-prussian-blue;
            }
            &:nth-child(2) {
              border-bottom: 1px solid $sb-aquamarine;
              padding-bottom: 30px;
              color: $sb-aquamarine;
            }
          }
          p {
            color: $sb-aquamarine;
            text-align: right;
          }
        }
      }
      div.row.who-were-looking-for {
        margin-top: 30px;
        h1 {
          color: $text-black;
          margin-bottom: 30px;
          strong {
            color: $sb-aquamarine;
            font-weight: 400;
          }
        }
        ul#who_were_looking_for_tabs {
          li.nav-item {
            transition: 0.5s all;
            a {
              background-color: $text-black;
              color: $white;
              border-radius: 0;
              border: none;
              padding: 8px 60px;
              transition: all 0.3s ease-in-out;
              margin-right: 2px;
              &.active {
                background-color: $sb-aquamarine;
              }
            }
          }
        }
        div#myTabContent {
          margin-bottom: 60px;
          div.tab-pane {
            div.carousel {
              ol.carousel-indicators {
                li {
                  width: auto;
                  padding: 10px;
                }
              }
              div.carousel-inner {
                div.carousel-caption {
                  display: grid !important;
                  align-items: center;
                  text-align: left;
                  padding: 3% 5%;
                  h2,
                  h3,
                  h4,
                  h5 {
                    color: $white;
                  }
                  h3 {
                    font-size: 2.8rem;
                    font-weight: 600;
                    margin-bottom: 80px;
                    line-height: 50px;
                  }
                  h5 {
                    font-size: 1.4rem;
                    font-weight: 500;
                  }
                  p,
                  li {
                    color: $white !important;
                    font-size: 1.2rem;
                    line-height: 1.7rem;
                    strong {
                      font-weight: 500;
                      color: #fff;
                    }
                  }
                  a {
                    text-decoration: underline;
                    &:hover {
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
      div.mobile-content {
        div.col-12 {
          div.mobile-section {
            margin-bottom: 30px;
            div.mobile-header {
              display: block;
              position: relative;
              width: 100%;
              height: auto;
              h3.title {
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 181px;
                padding: 10px;
                background-color: rgba(0, 0, 0, 0.7);
                margin-bottom: 0;
              }
            }
            div.mobile-content {
              padding: 30px;
              &:nth-child(even) {
                background-color: $sb-aquamarine;
              }
              &:nth-child(odd) {
                background-color: $sb-prussian-blue;
              }
              h4 {
                margin-bottom: 25px;
              }
              p {
                color: #ffffff;
                strong {
                  color: #ffffff;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      div.row.benefits-content {
        h1 {
          color: $text-black;
          margin-bottom: 30px;
        }
        h4 {
          color: $text-black;
          margin-bottom: 30px;
        }
        p {
          color: $text-black;
          strong {
            color: $sb-aquamarine;
            font-weight: 400;
          }
        }
      }
      div.row.benefits-items {
        margin-bottom: 75px;
        div.item {
          text-align: center;
          padding: 20px;
          background: $jade-70;
          margin: 10px 0;
          height: calc(100% - 20px);
          img {
            width: 75%;
            height: auto;
            margin-bottom: 0;
          }
          h5 {
            color: $sb-aquamarine;
          }
          p {
            color: $text-black;
          }
        }
      }
      div.row.vacancies-row {
        margin-bottom: 75px;
        background-color: #f0f0f0;
        padding: 40px 0;
        div.vacancies-wrapper {
          padding-right: 60px;
          h1,
          p {
            color: $text-black;
          }
          a {
            color: $sb-aquamarine;
          }
          div.vacancies {
            margin-top: 50px;
            div.vacancy {
              margin-bottom: 40px;
              h5 {
                color: $text-black;
                strong {
                  color: $sb-aquamarine;
                  font-weight: 500;
                }
              }
              p {
                margin-bottom: 20px;
              }
              a.learn-more-button {
                background: $sb-aquamarine;
                padding: 10px;
                color: #fff;
                &:after {
                  position: relative;
                  display: inline-block;
                  content: '';
                  width: 40px;
                  height: 18px;
                  top: 3px;
                  background-image: url('/static/images/symbols/arrow-white.svg');
                  background-size: cover;
                  background-position: center center;
                  background-repeat: no-repeat;
                  margin-left: 30px;
                  transition: transform 0.6s ease-in-out;
                }

                &:hover {
                  text-decoration: none;
                  &:after {
                    transform: rotateX(1turn);
                  }
                }
              }
            }
          }
        }
        div.recent-posts {
          text-align: right;
          h5 {
            color: $sb-aquamarine;
            line-height: 28px;
            border-bottom: 1px solid $sb-aquamarine;
            margin-bottom: 20px;
          }
          div.article-wrapper {
            a.article {
              display: block;
              &:hover {
                text-decoration: none;
                p.title {
                  text-decoration: underline;
                }
                p.release-date {
                  text-decoration: none;
                }
              }
              p.title {
                position: relative;
                display: inline;
                color: $text-black;
                margin-bottom: 5px;
                padding-left: 30px;
                &::before {
                  display: block;
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 0;
                  width: 14px;
                  height: 20px;
                  background-image: url('/static/images/symbols/bluetriangle.svg');
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center center;
                }
              }
              p.release-date {
                color: $sb-aquamarine;
              }
            }
          }
        }
      }
      div.row.employees {
        div.title {
          h1 {
            strong {
              color: $sb-prussian-blue;
              font-weight: 400;
            }
          }
        }
        div.what_our_employees_think {
          display: grid;
          grid-template-columns: 29% 69%;
          column-gap: 2%;
          padding: 20px;
          margin: 20px;
          background-color: $sb-prussian-blue-lightest;
          justify-items: center;
          align-items: center;
          .author {
            display: block;
            text-align: center;
            img {
              display: block;
              width: 200px;
              height: 200px;
              object-fit: cover;
              margin-bottom: 15px;
              margin-left: auto;
              margin-right: auto;
              border-radius: 50%;
            }
            p {
              margin-bottom: 0;
              &.name {
                color: $grey_blue;
                font-weight: 500;
              }
            }
          }
          div.content {
            h5 {
              color: $text-black;
              line-height: 34px;
              strong {
                font-weight: 600;
                color: $grey_blue;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: $medium-breakpoint) {
    div.video-banner {
      div.video-wrapper {
        top: -105px;
        margin-bottom: -105px;
      }
    }
    div.content div.container {
      div.row.employees {
        div.what_our_employees_think {
          grid-template-columns: 1fr;
          .author {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
  @media screen and (max-width: $small-breakpoint) {
  }
}
