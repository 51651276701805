div#article-page {
  div.banner {
    position: relative;
    div.container {
      position: relative;
      padding: 0;
    }
    div.image-wrapper {
      position: relative;
      display: block;
      width: 104%;
      left: -2%;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    div.content-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      div.logo {
        @media screen and (max-width: $small-breakpoint) {
          display: none;
        }
      }
    }
  }
  div.content {
    div.container {
      background: $white;
      div.title {
        h2 {
          color: $prussian-blue;
          margin: 20px 0;
        }
        p {
          font-size: 13px;
          line-height: 18px;
          font-weight: 300;
          color: $prussian-blue;
          margin: 0;
          span {
            color: #8695AA;
          }
          &.author {
            font-weight: 400;
          }
        }
      }
      div.article-content {
        position: relative;
        padding: 30px 0;
        div.details {
          h4 {
            color: $prussian-blue;
            padding-bottom: 5px;
            border-bottom: 1px solid $sb-tan;
            margin: 10px 0;
          }
          div.author-details {
            border-top: 1px solid $sb-tan;
            img.guest-author {
              width: 100px;
              height: auto;
              margin-top: 10px;
              margin-bottom: 20px;
            }
            img.report-preview {
              display: block;
              width: 100%;
            }
            div.image-wrapper {
              position: relative;
              img.author-photo {
                width: 100%;
                height: auto;
              }
              div.author-name-wrapper {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 80%;
                padding: 10px;
                background: rgba(0,0,0,0.5);
                a.author-name {
                  margin-bottom: 0;
                  cursor: pointer;
                  &:hover {
                    text-decoration: none;
                    color: #FFFFFF;
                  }
                }
              }
            }
            div.contact-info-wrapper {
              position: relative;
              display: block;
              width: 100%;
              height: auto;
              margin-bottom: 20px;
              div.location {
                background: #70afb9;
                color: $white;
                padding: 10px;
                font-size: 0.9rem;
                a {
                  &:hover {
                    text-decoration: none;
                    color: #FFFFFF;
                  }
                }
              }
              div.contact-info {
                background: #f2f2f3;
                padding: 10px;
                a {
                  font-size: 0.9rem;
                  display: block;
                  color: $text-black;
                  margin-bottom: 5px;
                  &:before {
                    display: inline-block;
                    width: 13px;
                    height: auto;
                    vertical-align: middle;
                    margin-right: 10px;
                  }
                  &:hover {
                    text-decoration: none;
                  }
                  &.phone {
                    &:before {
                      content: url("../images/symbols/ddi.svg");
                    }
                  }
                  &.mobile {
                    &:before {
                      content: url("../images/symbols/mobile.svg");
                    }
                  }
                  &.email {
                    &:before {
                      content: url("../images/symbols/envelope.svg");
                      width: 19px;
                    }
                  }
                }
              }
            }
          }
          div.sharing {
            margin-bottom: 30px;
            h4 {
              margin-top: 30px;
            }
            a {
              display: inline-block;
              height: 30px;
              width: 30px;
              background-size: contain;
              background-position: center center;
              background-repeat: no-repeat;
              margin-right: 10px;
              &.fb-share {
                background-image: url('../images/symbols/social/blogFacebook.svg');
              }
              &.twitter-share-button {
                background-image: url('../images/symbols/social/blogTwitter.svg');
              }
              &.linkedin-share {
                background-image: url('../images/symbols/social/blogLikedIn.svg');
              }
              &.print-button {
                background-image: url('../images/symbols/insights/printer.svg');
              }
            }
          }
          div.recent-posts {
            ul {
              margin: 0;
              padding: 0 0 0 20px;
              list-style-image: url('../images/symbols/tantriangle.png');
              li {
                margin: 10px 0;
                padding: 0;
                a {
                  display: block;
                  &:hover {
                    text-decoration: none;
                    text-decoration-color: $text-black;
                  }
                  p {
                    font-size: 0.9rem;
                    color: $text-black;
                    margin: 0;
                    &.date {
                      color: $prussian-blue;
                    }
                  }
                }
              }
            }
          }
        }
        div.content {
          div.wrapper {
            padding-top: 30px;
            border-top: 1px solid $sb-tan;
            h3 {
              font-size: 24px;
              line-height: 28.8px;
              margin-bottom: 10px;
              color: $prussian-blue;
            }
            p, a, li, span {
              color: $text-black;
              text-decoration-color: $text-black;
            }
            a,u {
              text-decoration: none;
              font-weight: 400;
              color: $sb-aquamarine;
              overflow-wrap: break-word;
            }
            strong {
              font-weight: 400;
            }
            ul, ol {
              padding-left: 18px;
              font-weight: 400;
              li {
                font-weight: 400;
                span {
                  font-weight: 300;
                }
              }
            }
            ol {
              li {
                padding-left: 15px;
              }
            }
            sup {
              font-style: normal;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              border-spacing: 0;
              margin: 0;
              border: none;
              thead {
                tr {
                  background: $jade;
                  th {
                    color: #FFFFFF;
                    padding: 5px;
                    background: $jade;
                    border: none;
                    span {
                      color: #FFFFFF !important;
                      strong {
                        font-weight: 400;
                        span {
                          background-color: $jade !important;
                        }
                      }
                    }
                  }
                }
              }
              tbody {
                tr {
                  border-bottom: 1px solid $jade;
                  td {
                    padding: 5px;
                    border: none;
                  }
                }
              }
            }
            a.download-button-wrapper {
              display: block;
              width: 400px;
              background: $sb-tan;
              margin-bottom: 60px;
              cursor: pointer;
              p {
                display: inline-block;
                color: #FFF;
                margin-left: 15px;
                margin-right: 160px;
              }
              img.download-icon {
                display: inline-block;
                width: 40px;
                height: auto;
                margin: 15px;
              }
            }
            img.content-end {
              display: block;
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
}
