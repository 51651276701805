div.row.button-list {
  padding: 0;
  div.col-12 {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      float: right;
      li {
        display: inline-block;
        margin-bottom: 30px;
        margin-left: 1px;
        a {
          display: block;
          position: relative;
          background: $sb-orange;
          font-size: 13px;
          color: $white !important;
          padding: 3px 8px;
          height: 30px;
          &:after {
            content: url("../images/symbols/arrow-white.svg");
            display: inline-block;
            padding-left: 13px;
            width: 37px;
            padding-top: 2px;
          }
          &:hover {
            text-decoration: none;
          }
          &:hover:after {
            transition: all 0.6s;
            -webkit-transform: rotateX(1turn);
            transform: rotateX(1turn);
          }

          &.back-button {
            &:before {
              content: url("../images/symbols/arrow-left-white.svg");
              display: inline-block;
              padding-right: 13px;
              width: 37px;
              padding-top: 2px;
            }
            &:hover:before {
              transition: all 0.6s;
              -webkit-transform: rotateX(1turn);
              transform: rotateX(1turn);
            }
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
}

// Overwrite default bootstrap buttons
.btn {border-radius: 0px;}

.btn-success {
  background-color:$jade;
  border-color: $jade;
  &:hover, &:active, &:not(:disabled):not(.disabled).active, &:not(:disabled):not(.disabled):active, {
    background-color:$grey_blue;
    border-color: $grey_blue;
  }
  &:focus, &:focus:not(:disabled):not(.disabled).active:focus, &:focus:not(:disabled):not(.disabled):active:focus {box-shadow:0 0 0 0.2rem rgba(0,123,255,.25);}

}
