div.quick-links {
  div.container {
    padding-bottom: 80px;
    padding-top: 50px;
    background: $white;
    h2 {
      color: $text-black;
      span {
        color: $sb-aquamarine;
      }
      @media screen and (max-width: $small-breakpoint) {
        padding-left: 15px;
      }
    }
    div.link-wrapper {
      border-top: 1px solid $text-black;
      border-bottom: 1px solid $text-black;
      padding: 30px 0;
      a {
        color: $text-black;
        transition: all 0.5s;
        &:hover {
          text-decoration: none;
          p {
            color: $sb-aquamarine;
            transition: all 0.2s;
          }
        }
        img {
          width: 100%;
          height: auto;
          display: block;
        }
        p {
          color: $text-black;
          text-transform: uppercase;
          margin-top: 5px;
          font-size: 13px;
          line-height: 18px;
          font-weight: 400;
        }
      }
      @media screen and (max-width: $small-breakpoint) {
        div.quick-link {
          padding-left: 9%;
          padding-right: 9%;
          &:nth-child(odd) {
            padding-right: 4%;
          }
          &:nth-child(even) {
            padding-left: 4%;
          }
          img {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
          p {
            text-align: left;
          }
        }

      }
    }
  }
}
