@mixin linkarrow {
    &:after {
            content: url("../images/symbols/arrow.svg");
            display: inline-block;
            padding-left: 13px;
            width: 37px;
            padding-top: 2px;
          }
          &:hover {
            text-decoration: none;
          }
          &:hover:after {
            transition: all 0.6s;
            -webkit-transform: rotateX(1turn);
            transform: rotateX(1turn);
          }
}