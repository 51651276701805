div.accordian {
  margin: 60px 0;
  div.section {
    position: relative;
    div.title {
      padding: 15px 0;
      a {
        display: block;
        margin: 0 15px;
        font-size: 1.2rem;
        &:hover {
          color: $white;
          text-decoration: none;
        }
      }
      div.accordian-expand {
        position: absolute;
        right: 30px;
        top: 5px;
        padding: 8px 10px;
        border-radius: 50px;
        border: 2px solid $white;
        cursor: pointer;
        height: 47px;
        &.open {
          div.bar1 {
            -webkit-transform: rotateZ(1turn);
            transform: rotateZ(1turn);
          }
          div.bar2 {
            -webkit-transform: rotateZ(1.5turn);
            transform: rotateZ(1.5turn);
            width: 0;
            height: 0;
          }
        }
        div.bar1 {
          position: relative;
          top: 13px;
          left: 0;
          width: 25px;
          height: 2px;
          background: $white;
          transition: all 0.3s ease-in-out;
        }
        div.bar2 {
          position: relative;
          top: 0;
          left: 12px;
          width: 2px;
          height: 25px;
          background: $white;
          transition: all 0.3s ease-in-out;
        }
      }
    }
    div.content {
      max-height: 0;
      transition: max-height 0.5s ease-in-out;
      overflow: hidden;
      &.open {
        max-height: 1000px;
      }
      ul {
        list-style-type: none;
        padding-left: 15px;
        margin: 15px 0;
        li {
          a {
            display: block;
            color: $text-black;
            margin: 5px 0;
          }
        }
      }
    }
    &#accordian-partners {
      div.title {
        background: $sb-aquamarine;
      }
      div.content {
        border-bottom: 2px solid $sb-aquamarine;
        p {
          font-weight: 400;
          color: $text-black;
          margin: 15px;
        }
      }
    }
    &#accordian-consultants {
      div.title {
        background: $prussian-blue;
      }
      div.content {
        border-bottom: 2px solid $prussian-blue;
      }
    }
    &#accordian-team {
      div.title {
        background: $sb-tan;
      }
      div.content {
        border-bottom: 2px solid $sb-tan;
      }
    }
  }
}
